<template>
  <el-card>
    <div slot="header">
      <h2>{{$t('page.console.menu.list')}}</h2>
    </div>

    <el-button type="primary" size="mini" @click="goTo">{{$t('button.add')}}</el-button>
    <el-button type="danger" :disabled="disabled" size="mini" @click="removeProduct(deleteCategory)">{{$t('button.del')}}</el-button>

    <el-form :inline="true" class="mt20">
      <el-form-item :label="$t('page.product.name')">
        <el-input v-model="query" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch" size="mini">{{$t('search')}}</el-button>
      </el-form-item>
    </el-form>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="(type, i) in types" :key="type.value" :label="type[isEn + 'name']" :name="type.value"></el-tab-pane>
    </el-tabs>

    <el-table
        class="mt20"
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        size="small"
        @selection-change="productChange"
        tooltip-effect="dark">
      <el-table-column
          type="selection">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          :prop="isEn+`title`"
          :label="$t('page.product.name')"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          :prop="isEn+`desc`"
          :label="$t('page.product.desc')"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="create_time"
          :label="$t('create_time')"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="update_time"
          :label="$t('update_time')"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          width="200"
          :label="$t('action')">
        <template v-slot="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit(scope.row.id)">{{$t('button.edit')}}</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeProduct(scope.row.id)">{{$t('button.del')}}</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-pagination
        class="mt20 mb20 line"
        background
        :hide-on-single-page="onePage"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        @size-change="get"
        @current-change="get"
        layout="prev, pager, next">
    </el-pagination>
  </el-card>
</template>

<script>
import {getPosts, removePosts} from "@/api/os/admin";
import config from '@/config';

export default {
  data() {
    return {
      activeName: config.default.technicalType[0].value,
      types: config.default.technicalType,
      list: [],
      multiple: false,
      currentPage: 0,
      pageSize: config.default.pageLimit,
      pageSizes: config.default.pageSizes,
      onePage: false,
      total: 0,
      loading: false,
      status: config.default.productStatus,
      // 删除数据
      deleteCategory: '',
      disabled: true,
      selected: 0,

      query: ''
    }
  },
  created() {
    this.get()
  },
  computed: {
    // add
    isEn() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }

      return 'en_';
    }
  },
  methods: {
    get() {
      this.loading = true
      getPosts({
        page: this.currentPage,
        pageSize: this.pageSize,
        query: this.query,
        type: this.activeName
      }).then(res => {
        this.loading = false
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    productChange(selection) {
      let data = []
      selection.forEach((v, i) => {
        data.push(v.id)
      })
      this.selected = data.length
      this.deleteCategory = data.join(',')

      if(this.deleteCategory) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    handleClick() {
      this.get()
    },
    goTo() {
      this.$router.push('/console/technicalSupport')
    },
    edit(id) {
      this.$router.push({
        path: 'technicalSupport',
        query: {
          id: id
        }
      })
    },
    // 删除选中的id
    removeProduct(id) {
      if(!id) {
        this.$message.error(this.$t('tips.not_selected'))
        return
      }
      this.$confirm(this.$t('tips.del'), this.$t('tip'), {
        type: 'warning'
      }).then(() => {
        removePosts(id).then(res => {
          if(res.data.code === 1) {
            this.$message({
              type: 'success',
              message: this.$t('tips.success')
            });
            this.get()
          }
        })
      })
    },
    onSearch() {
      this.currentPage = 0
      this.get()
    }
  }
}
</script>